<nav class="navbar navbar-expand-lg navbar-light" style="background-color:rgb(81,201,99)">
  <div class="container-fluid">
    <p class="navbar-brand fw-bold" href="#">AFTC Members Details</p>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav" type="button">
      <ul class="navbar-nav">
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/add-new-members" href="#" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact:true}">AFTC Members Entry Form</p>
        </li>
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/depotView" href="#" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact:true}">AFTC Members Data View</p>
        </li>
      </ul>
    </div>
  </div>
</nav>
<section class="content px-2 py-3">
  <form [formGroup]="depositDetailsForm">
    <div class="row py-4"></div>
    <h3 class="text-left">Depot Registration</h3>
    <div class="row">

      <div class="col-lg-4 col-md-6 col-12">
        <div class="form-floating mt-3">
          <input type="text" class="form-control" placeholder="Member Name" formControlName="depotOccupantName"
            [ngClass]="{'is-invalid': depositDetailsForm.get('depotOccupantName')?.invalid && depositDetailsForm.get('depotOccupantName')?.touched,
                    'is-valid': depositDetailsForm.get('depotOccupantName')?.valid && depositDetailsForm.get('depotOccupantName')?.touched}">
          <label>Member Name <span class="text-danger">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.depotOccupantName"
          class="text-danger">{{depositFormErrors.depotOccupantName}}</span>
      </div>

      <div class="col-lg-4 col-md-6 col-12">
        <div class="form-floating mt-3">
          <div class="custom-dropdown-wrapper">
            <ng-multiselect-dropdown [placeholder]="'Select Depot Number'" [settings]="dropdownSettings"
              [data]="depotNumberList" [(ngModel)]="selectedItemsDepotNumber"
              (onSelect)="onItemSelectDepotNumber($event)" (onSelectAll)="onSelectAllDepotNumber($event)"
              (change)="selectedDepotNumber($event)" (onDeSelect)="onItemDeSelectDepotNumber($event)"
              (onDeSelectAll)="onDeSelectDepotNumberAll($event)" class="form-control" formControlName="testDepotNo">
            </ng-multiselect-dropdown>
          </div>
          <!-- <label>Depot Number</label> -->
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12">
        <div class="form-floating mt-3">
          <div class="custom-dropdown-wrapper">
            <ng-multiselect-dropdown [placeholder]="'Select SL Depot Number'" [settings]="dropdownSettings"
              [data]="slDepotNumberList" [(ngModel)]="selectedItemsSLDepotNumber"
              (onSelect)="onItemSelectSLDepotNumber($event)" (onSelectAll)="onSelectAllSLDepotNumber($event)"
              (change)="selectedSLDepotNumber($event)" (onDeSelect)="onItemDeSelectSLDepotNumber($event)"
              (onDeSelectAll)="onDeSelectSLDepotNumberAll($event)" class="form-control" formControlName="testSlDepotNo">
            </ng-multiselect-dropdown>
          </div>
          <!-- <label>Depot Number</label> -->
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12">
        <div class="form-floating mt-3">
          <select class="form-select" aria-label="Default select example" formControlName="aftcMembershipType"
            placeholder="Aftc Memember Type"
            [ngClass]="{'is-invalid': depositDetailsForm.get('aftcMembershipType')?.invalid && depositDetailsForm.get('aftcMembershipType')?.touched,
                    'is-valid': depositDetailsForm.get('aftcMembershipType')?.valid && depositDetailsForm.get('aftcMembershipType')?.touched}">
            <option selected disabled value="">Select a AFTC Memember Type</option>
            <option value="SH">Stack Holder (SH)</option>
            <option value="UM">Utility Member (UM)</option>
          </select>
          <label>AFTC Memember Type <span class="text-danger">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.aftcMembershipType"
          class="text-danger">{{depositFormErrors.aftcMembershipType}}</span>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-3">
        <div class="form-floating">
          <div class="custom-dropdown-wrapper">
            <ng-multiselect-dropdown [placeholder]="'Select Service Type'" [settings]="dropdownSettings"
              [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)" (change)="selectedServiceType($event)"
              (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onDeSelectAll($event)" class="form-control"
              formControlName="testServiceType"
              [ngClass]="{'is-invalid': depositDetailsForm.get('testServiceType')?.invalid && depositDetailsForm.get('testServiceType')?.touched,
                    'is-valid': depositDetailsForm.get('testServiceType')?.valid && depositDetailsForm.get('testServiceType')?.touched}">
            </ng-multiselect-dropdown>
          </div>
        </div>

        <span *ngIf="depositFormErrors.testServiceType" class="text-danger">
          {{ depositFormErrors.testServiceType }}
        </span>
      </div>

      <div class="col-lg-4 col-md-6 col-12" *ngIf="this.isRentalType">
        <div class="form-floating mt-3">
          <input type="text" class="form-control" placeholder="User Name" formControlName="userName"
            (input)="checkUserName($event)"
            [ngClass]="{'is-invalid': depositDetailsForm.get('userName')?.invalid && depositDetailsForm.get('userName')?.touched,
                    'is-valid': depositDetailsForm.get('userName')?.valid && depositDetailsForm.get('userName')?.touched}">
          <label>User Name <span class="text-danger">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.userName" class="text-danger">{{depositFormErrors.userName}}</span>
      </div>

      <div class="col-lg-4 col-md-6 col-12" *ngIf="this.isRentalType">
        <div class="form-floating mt-3">
          <input type="text" class="form-control" placeholder="Password" formControlName="password"
            [ngClass]="{'is-invalid': depositDetailsForm.get('password')?.invalid && depositDetailsForm.get('password')?.touched,
                    'is-valid': depositDetailsForm.get('password')?.valid && depositDetailsForm.get('password')?.touched}">
          <label>Password <span class="text-danger">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.password" class="text-danger">{{depositFormErrors.password}}</span>
      </div>

      <div class="col-lg-4 col-md-6 col-12" *ngIf="this.isRentalType">
        <div class="form-floating mt-3">
          <input type="tel" class="form-control" placeholder="Phone Number" formControlName="phoneNumber"
            (keypress)="OnlyNumbers($event)" maxlength="10"
            [ngClass]="{'is-invalid': depositDetailsForm.get('phoneNumber')?.invalid && depositDetailsForm.get('phoneNumber')?.touched,
                    'is-valid': depositDetailsForm.get('phoneNumber')?.valid && depositDetailsForm.get('phoneNumber')?.touched}">
          <label>Phone Number <span class="text-danger">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.phoneNumber" class="text-danger">{{depositFormErrors.phoneNumber}}</span>
      </div>

      <div class="col-lg-4 col-md-6 col-12" *ngIf="this.isRentalType">
        <div class="form-floating mt-3">
          <select class="form-select" aria-label="Default select example" formControlName="paymentModeThroughAftcAccount"
          placeholder="Payment Type" (click)="onSelectPaymentType($event)"
          [ngClass]="{'is-invalid': depositDetailsForm.get('paymentModeThroughAftcAccount')?.invalid && depositDetailsForm.get('paymentModeThroughAftcAccount')?.touched,
                  'is-valid': depositDetailsForm.get('paymentModeThroughAftcAccount')?.valid && depositDetailsForm.get('paymentModeThroughAftcAccount')?.touched}">
          <option selected disabled value="">Select a Payment Type</option>
          <option value="YES">Online Payment</option>
          <option value="NO">Through AFTC</option>
          </select>
          <label>Payment Type <span class="text-danger">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.paymentModeThroughAftcAccount" class="text-danger">{{depositFormErrors.paymentModeThroughAftcAccount}}</span>
      </div>

      <div class="col-lg-4 col-md-6 col-12" *ngIf="this.isRentalType && this.isShowDepositAmount">
        <div class="form-floating mt-3">
          <input type="tel" class="form-control" placeholder="Deposit Amount" formControlName="initialDepositAmount"
            (keypress)="OnlyNumbers($event)" maxlength="10"
            [ngClass]="{'is-invalid': depositDetailsForm.get('initialDepositAmount')?.invalid && depositDetailsForm.get('initialDepositAmount')?.touched,
                    'is-valid': depositDetailsForm.get('initialDepositAmount')?.valid && depositDetailsForm.get('initialDepositAmount')?.touched}">
          <label>Deposit Amount <span class="text-danger">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.initialDepositAmount" class="text-danger">{{depositFormErrors.initialDepositAmount}}</span>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-3" *ngIf="this.isRentalType">
        <div class="form-floating">
          <select class="form-select" Placeholder="Select a Rental Type" (change)="selectRentalType($event)" formControlName="coldStorageRentalType"
          [ngClass]="{'is-invalid': depositDetailsForm.get('coldStorageRentalType')?.invalid && depositDetailsForm.get('coldStorageRentalType')?.touched,
                    'is-valid': depositDetailsForm.get('coldStorageRentalType')?.valid && depositDetailsForm.get('coldStorageRentalType')?.touched}">
            <option selected disabled value="">Select a Rental Type</option>
            <option value="FLOOR">Full Floor Rent</option>
            <option value="SPACE">Space Rent</option>
            <option value="OPEN_SPACE">Open Space Rent</option>
          </select>
          <label class="form-label">Rental Type <span class="text-danger">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.coldStorageRentalType" class="text-danger">{{depositFormErrors.coldStorageRentalType}}</span>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-3" *ngIf="this.isFullFloorRent">
        <div class="form-floating">
          <select class="form-select" Placeholder="Full Floor Number" (change)="selectFloorNumber($event)" formControlName="coldStorageFloorNumber"
          [ngClass]="{'is-invalid': depositDetailsForm.get('coldStorageFloorNumber')?.invalid && depositDetailsForm.get('coldStorageFloorNumber')?.touched,
                    'is-valid': depositDetailsForm.get('coldStorageFloorNumber')?.valid && depositDetailsForm.get('coldStorageFloorNumber')?.touched}">
            <option selected disabled value="">Select a Full Floor Number</option>
            <option *ngFor="let floor of this.fullFloorList" [value]="floor">{{ floor }}</option>
          </select>
          <label class="form-label">Full Floor Number <span class="text-danger">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.coldStorageFloorNumber" class="text-danger">{{depositFormErrors.coldStorageFloorNumber}}</span>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-3" *ngIf="isSpaceNumber">
        <div class="form-floating">
          <div class="custom-dropdown-wrapper">
            <ng-multiselect-dropdown [placeholder]="'Select Space Number'" [settings]="ColdStorageSettings"
              [data]="coldStorageList" [(ngModel)]="selectedItemsColdStorageSpaceNumber"
              (onSelect)="onItemSelectSpaceNumber($event)" (onSelectAll)="onSelectAllSpaceNumber($event)"
              (change)="selectedSpaceNumber($event)" (onDeSelect)="onItemDeSelectSpaceNumber($event)"
              (onDeSelectAll)="onDeSelectSpaceNumberAll($event)" class="form-control" formControlName="testSpaceNumber"
              [ngClass]="{'is-invalid': depositDetailsForm.get('testSpaceNumber')?.invalid && depositDetailsForm.get('testSpaceNumber')?.touched,
                        'is-valid': depositDetailsForm.get('testSpaceNumber')?.valid && depositDetailsForm.get('testSpaceNumber')?.touched}">
            </ng-multiselect-dropdown>
          </div>
          <span *ngIf="depositFormErrors.testSpaceNumber" class="text-danger">{{depositFormErrors.testSpaceNumber}}</span>
        </div>

        <span *ngIf="depositFormErrors.testServiceType" class="text-danger">
          {{ depositFormErrors.testServiceType }}
        </span>
      </div>

      <div class="text-center py-3"></div>
      <div class="text-center">
        <button type="button" class="btn btn-success" (click)="formValidationDepot()">Save</button>
      </div>
      <div class="text-center py-3"></div>
    </div>
  </form>
</section>

<ngx-spinner>
  <div class="loaderssm">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>