import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanLoad,
  Route,
  UrlSegment
} from "@angular/router";
import { Observable } from "rxjs";
import { AppService } from "@services/app.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate, CanActivateChild {
  department: string = "";
  role: string = "";

  constructor(private router: Router, private appService: AppService) {
  }

  ngOnInit(): void {
    this.department = localStorage.getItem("department");
  }

  canLoad(
    route: string
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (route == "/login") {
      if (this.department == "") {
        this.router.navigate(["/login"]);
        return true;
      } else {
        this.router.navigate(["/"]);
        return true;
      }
    }

    this.department = localStorage.getItem("department");
    this.role = localStorage.getItem("role");

    switch (this.role) {
      case "USER":
        this.departmentFilter(route);
        break;
      case "ADMIN":
        //this.router.navigate(['/main/dash']);
        return false;
      default:
        this.router.navigate(["/"]);
        break;
    }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.getProfile();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.canLoad(state.url);
    return this.canActivate(next, state);
  }

  async getProfile() {
    if (this.appService.user) {
      return true;
    }
    try {
      await this.appService.getProfile();
      return true;
    } catch (error) {
      return false;
    }
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()).join("/"))
      .join("/");
  }

  getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    return (
      "/" +
      route.pathFromRoot
        .filter((v) => v.routeConfig)
        .map((v) => v.routeConfig!.path)
        .join("/")
    );
  }



  departmentFilter(route: string) {
    console.log("route::", route);
    switch (this.department) {
      case "GATE KEEPER": {
        if (route == "/main/tollgate-new-entry" || route == "/") {
          return true;
        } else if (route == "/main/dash") {
          this.router.navigate(["/main/tollgate-new-entry"]);
          return true;
        } else if (route == "/main/frontOffice" || route == "/") {
          return true;
        } else if (route == "/main/depositEntry" || route == "/") {
          return true;
        } else if (route == "/main/deliveryEntry" || route == "/") {
          return true;
        } else if (route == "/toll-gate-print" || route == "/") {
          return true;
        } else if (route == "/main/tollGateView" || route == "/") {
          return true;
        }
        else {
          this.router.navigate(["/"]);
          return false;
        }
        break;
      }
      case "ACCOUNTANT": {
        if (route == "/main/tollGateCash" || route == "/") {
          return true;
        } else if (route == "/main/dash") {
          this.router.navigate(["/main/tollGateCash"]);
          return true;
        } else if (route == "/main/weigh-bridge-cash" || route == "/") {
          return true;
        } else {
          this.router.navigate(["/"]);
          return false;
        }
        break;
      }

      case "FRONT END OFFICE": {
        if (route == "/main/front-office-entry" || route == "/") {
          return true;
        } else if (route == "/main/dash") {
          this.router.navigate(["/main/front-office-entry"]);
          return true;
        } else if (route == "/main/frontOffice" || route == "/") {
          return true;
        }
        else if (route == "/main/depositEntry" || route == "/") {
          return true;
        } else if (route == "/deposit-print" || route == "/") {
          return true;
        } else if (route == "/main/deliveryEntry" || route == "/") {
          return true;
        } else if (route == "/main/preprocessing-deposit" || route == "/") {
          return true;
        } else if (route == "/main/preprocessing-delivery" || route == "/") {
          return true;
        } else if (route == "/main/cold-storage-deposit" || route == "/") {
          return true;
        } else if (route == "/main/cold-storage-delivery" || route == "/") {
          return true;
        } else if (route == "/main/packing-deposit" || route == "/") {
          return true;
        } else if (route == "/main/packing-delivery" || route == "/") {
          return true;
        } else if (route == "/main/stack-holders" || route == "/") {
          return true;
        } else if (route == "/main/stack-details-view" || route == "/") {
          return true;
        } else if (route == "/main/stock-details-view" || route == "/") {
          return true;
        } else if (route == "/main/tollGateCash" || route == "/") {
          return true;
        } else if (route == "/main/weigh-bridge-cash" || route == "/") {
          return true;
        } else if (route == "/main/solar-tunnel-dryer-deposit" || route == "/") {
          return true;
        } else if (route == "/main/solar-tunnel-dryer-delivery" || route == "/") {
          return true;
        } else if (route == "/main/membershipView" || route == "/") {
          return true;
        } else if (route == "/main/admin-cold-storage-deposit-request-view" || route == "/") {
          return true;
        } else if (route == "/main/admin-cold-storage-delivery-request-view" || route == "/") {
          return true;
        } else if (route == "/main/stock-management" || route == "/") {
          return true;
        } else if (route == "/main/cold-storage-depositor-view" || route == "/") {
          return true;
        } else if (route == "/main/cold-storage-credits" || route == "/") {
          return true;
        } else if (route == "/main/commodity-details-view" || route == "/") {
          return true;
        }
        else {
          this.router.navigate(["/"]);
          return true;
        }
        break;
      }
      case "RECEPTIONIST": {
        if (route == "/main/aftcl-Report-Screen" || route == "/") {
          return true;
        } else if (route == "/main/dash") {
          this.router.navigate(["/main/aftcl-Report-Screen"]);
          return true;
        } else if (route == "/main/aftcl-Product-Screen" || route == "/") {
          return true;
        } else if (route == "/main/aftcl-add-Product-Screen" || route == "/") {
          return true;
        } else if (route == "/main/aftcl-Edit-Screen" || route == "/") {
          return true;
        } else if (route == "/main/pending-test" || route == "/") {
          return true;
        } else {
          this.router.navigate(["/"]);
          return true;
        }
        break;
      }
      case "QUALITY MANAGER": {
        if (route == "/main/aftcl-Edit-Screen" || route == "/") {
          return true;
        } else if (route == "/main/dash") {
          this.router.navigate(["/main/aftcl-Edit-Screen"]);
          return true;
        } else if (route == "/main/aftcl-Report-Screen" || route == "/") {
          return true;
        } else {
          this.router.navigate(["/"]);
          return true;
        }
        break;
      }
      case "TECHNICAL MANAGER": {
        if (route == "/main/aftcl-Edit-Screen" || route == "/") {
          return true;
        } else if (route == "/main/dash") {
          this.router.navigate(["/main/aftcl-Edit-Screen"]);
          return true;
        } else if (route == "/main/aftcl-Report-Screen" || route == "/") {
          return true;
        } else {
          this.router.navigate(["/"]);
          return true;
        }
        break;
      }
      case "TECHNICAL ANALYST": {
        if (route == "/main/aftcl-Edit-Screen" || route == "/") {
          return true;
        } else if (route == "/main/dash") {
          this.router.navigate(["/main/aftcl-Edit-Screen"]);
          return true;
        } else if (route == "/main/aftcl-Report-Screen" || route == "/") {
          return true;
        } else {
          this.router.navigate(["/"]);
          return true;
        }
        break;
      }
      case "WEIGH BRIDGE": {
        if (route == "/main/weigh-bridge-view" || route == "/") {
          return true;
        } else if (route == "/main/dash") {
          this.router.navigate(["/main/weigh-bridge-view"]);
          return true;
        } else if (route == "/main/weigh-bridge-entry" || route == "/") {
          return true;
        } else if (route == "/main/weigh-bridge-detail-view" || route == "/") {
          return true;
        } else {
          this.router.navigate(["/"]);
          return true;
        }
      }
      // case "DATA ENTRY": {
      //   if (route == "/main/impactStudy" || route == "/") {
      //     return true;
      //   } else if (route == "/main/dash") {
      //     this.router.navigate(["/main/stockDetails"]);
      //     return true;
      //   } else if (route == "/main/impactDataView" || route == "/") {
      //     return true;
      //   }
      //   else if (route == "/main/impactDashboard" || route == "/") {
      //     return true;
      //   }
      //   else if (route == "/main/stockDetails" || route == "/") {
      //     return true;
      //   } else if (route == "/main/depotDetails" || route == "/") {
      //     return true;
      //   } else if (route == "/main/depotView" || route == "/") {
      //     return true;
      //   }
      //   else if (route == "/main/depotVehicleDetails" || route == "/") {
      //     return true;
      //   }
      //   else if (route == "/main/adminVehicleDetails" || route == "/") {
      //     return true;
      //   }
      //   else if (route == "/main/vehiclePunchDetails" || route == "/") {
      //     return true;
      //   }
      //   else if (route == "/main/add-new-members" || route == "/") {
      //     return true;
      //   }
      //   else {
      //     this.router.navigate(["/"]);
      //     return true;
      //   }
      //   break;
      // }
      case "NEWSFEED ADMIN": {
        if (route == "/main/newsFeedAdmin" || route == "/") {
          return true;
        } else if (route == "/main/dash") {
          this.router.navigate(["/main/newsFeedAdmin"]);
          return true;
        } else if (route == "/main/newsFeedView" || route == "/") {
          return true;
        } else {
          this.router.navigate(["/"]);
          return true;
        }
        break;
      }
      // case "CHAMBER ADMIN": {
      //   if (route == "/main/dash") {
      //     this.router.navigate(['/main/membershipView']);
      //     return true;
      //   } else if (route == "/main/membershipView" || route == "/") {
      //     return true;
      //   } else {
      //     this.router.navigate(["/"]);
      //     return true;
      //   }
      //   break;
      // }

      case "COOLING STORAGE": {
        if (route == "/main/dash") {
          this.router.navigate(['/main/admin-cold-storage-deposit-request-view']);
          return true;
        } else if (route == "/main/membershipView" || route == "/") {
          return true;
        } else if (route == "/main/admin-cold-storage-deposit-request-view" || route == "/") {
          return true;
        } else if (route == "/main/admin-cold-storage-delivery-request-view" || route == "/") {
          return true;
        } else if (route == "/main/stock-management" || route == "/") {
          return true;
        } else if (route == "/main/cold-storage-depositor-view" || route == "/") {
          return true;
        } else if (route == "/main/cold-storage-credits" || route == "/") {
          return true;
        } else if (route == "/main/commodity-details-view" || route == "/") {
          return true;
        }
        else {
          this.router.navigate(["/"]);
          return true;
        }
        break;
      }

      default: {
        //statements;
        break;
      }
    }
  }
}
