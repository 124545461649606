import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from '@/app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from '@modules/main/main.component';
import { LoginComponent } from '@modules/login/login.component';
import { HeaderComponent } from '@modules/main/header/header.component';
import { FooterComponent } from '@modules/main/footer/footer.component';
import { MenuSidebarComponent } from '@modules/main/menu-sidebar/menu-sidebar.component';
import { BlankComponent } from '@pages/blank/blank.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from '@pages/profile/profile.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegisterComponent } from '@modules/register/register.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { ToastrModule } from 'ngx-toastr';
import { MessagesComponent } from '@modules/main/header/messages/messages.component';
import { NotificationsComponent } from '@modules/main/header/notifications/notifications.component';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { UserComponent } from '@modules/main/header/user/user.component';
import { ForgotPasswordComponent } from '@modules/forgot-password/forgot-password.component';
import { RecoverPasswordComponent } from '@modules/recover-password/recover-password.component';
import { LanguageComponent } from '@modules/main/header/language/language.component';
import { MainMenuComponent } from './pages/main-menu/main-menu.component';
import { SubMenuComponent } from './pages/main-menu/sub-menu/sub-menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { ControlSidebarComponent } from './modules/main/control-sidebar/control-sidebar.component';
import { StoreModule } from '@ngrx/store';
import { authReducer } from './store/auth/reducer';
import { uiReducer } from './store/ui/reducer';
import { ProfabricComponentsModule } from '@profabric/angular-components';
import { defineCustomElements } from '@profabric/web-components/loader';
import { SidebarSearchComponent } from './components/sidebar-search/sidebar-search.component';
import { MatTooltipModule } from '@angular/material/tooltip';




import {
  NgbAlertModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { TollGateFormComponent } from '@components/aftc-digitalization/Tollgate/toll-gate-form/toll-gate-form.component';
import { ProductsComponent } from './components/aftc-digitalization/products/products.component';
import { EmployeeDetailsComponent } from './components/aftc-digitalization/employee-details/employee-details.component';
import { EntryListComponent } from './components/aftc-digitalization/entry-list/entry-list.component';
import { RenterDetailsComponent } from './renter-details/renter-details.component';
import { WeightBridgeEntryComponent } from '@components/aftc-digitalization/weigh-bridge/weigh-bridge-entry/weight-bridge-entry.component';
import { TollGatePrintScreenComponent } from "@components/aftc-digitalization/Tollgate/toll-gate-print-screen/toll-gate-print-screen.component";
import { PrintViewComponent } from "@components/aftc-digitalization/print-view/print-view.component";
import { FrontOfficeComponent } from "@components/aftc-digitalization/front-office/front-office.component";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxSpinnerModule } from "ngx-spinner";
import { AftcReportEntry } from "@components/labModule/aftc-report-entry/aftc-report-entry";
import { PrintDataService } from "@/model/labModal/PrintDataService";
import { AFTCProductComponent } from "@components/labModule/aftc-product/aftc-product.component";
import { AFTCPrintComponent } from "@components/labModule/aftc-print/aftc-print.component";
import { AFTCEditComponent } from "@components/labModule/aftc-edit/aftc-edit.component";
import { AFTCAddProductComponent } from "@components/labModule/aftc-add-product/aftc-add-product.component";
import { AFTCShowComponent } from "@components/labModule/aftc-show/aftc-show.component";
import { AFTCViewComponent } from "@components/labModule/aftc-view/aftc-view.component";
import { WarehouseDepositEntryFormComponent } from '@components/aftc-digitalization/Warehouse/warehouse-deposit/warehouse-deposit-entry-form.component';
import { WarehouseDeliveryEntryFormComponent } from '@components/aftc-digitalization/Warehouse/warehouse-delivery/warehouse-delivery-entry-form.component';
import { PendingTestComponent } from "@components/labModule/pending-test/pending-test.component";
import { CameraRecordsComponent } from "@components/camera-records/camera-records.component";
import { AftcTechAnalystComponent } from '@components/labModule/aftc-tech-analyst-print/aftc-tech-analyst-print.component';
import { StockDetails } from '@components/impact-study/Impact-data-entry/impact-data-entry.component';
import { ImpactDataView } from '@components/impact-study/Impact-data-view/Impact-data-view.component';
import { ImpactDashboard } from '@components/impact-study/Impact-dashboard/Impact-dashboard.component';
import { ImPactStudy } from '@components/impact-study/Impact-main-study/Impact-main-study.component';
import { NewsFeedAdminComponent } from "@components/news-feed-admin/news-feed-admin.component";
import { NewsFeedViewComponent } from "@components/news-feed-view/news-feed-view.component";
import { AftcMembershipView } from '@components/aftc-membership/aftc-membership-details-view/aftc-membership-view.component';
import { AftcMembershipForm } from '@components/aftc-membership/aftc-membership-form/aftc-membership-form.component';
import { BasicAftcMembershipForm } from '@components/aftc-membership/basic-aftc-membership-form/basic-aftc-membership-form.component';
import { SabpaisaWrapperComponent } from "@/SabPaisaWrapper";
import { ConfigLoader } from "@/config.loader";
import { ConfigService } from "@/config.service";
import { DepotEntryForm } from '@components/impact-study/aftc-depot-details/depot-entry-form.component';
import { AftcDepotView } from '@components/impact-study/aftc-depot-view/aftc-depot-view.component';
import { depotVehicleDataView } from '@components/impact-study/depot-vehicle-data-view/depot-vehicle-data-view.component';
import { AdminVehicleDataView } from '@components/impact-study/admin-vehicle-data-view/admin-vehicle-data-view.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { VehiclePunchDataView } from '@components/impact-study/vehicle-punch-data-view/vehicle-punch-data-view.component';
import { BasicAftcTestingMembershipForm } from '@components/aftc-membership/basic-aftc-testing-membership-form/basic-aftc-testing-membership-form.component';
import { AftcTestingMembershipForm } from '@components/aftc-membership/aftc-testing-membership-form/aftc-testing-membership-form.component';
import { WarehouseDeliveryPrintComponent } from "@components/aftc-digitalization/Warehouse/warehouse-delivery-print/warehouse-delivery-print.component";
import {
  WarehouseDepositPrintComponent
} from "@components/aftc-digitalization/Warehouse/warehouse-deposit-print/warehouse-deposit-print.component";
import { TollGateViewComponent } from '@components/aftc-digitalization/Tollgate/toll-gate-view/toll-gate-view.component';
import { TollGateOverallPrintComponent } from '@components/aftc-digitalization/Tollgate/toll-gate-cash-overall-print/toll-gate-cash-overall-print.component';
import { TollGateCashManagementComponent } from '@components/aftc-digitalization/Tollgate/toll-gate-cash-management/toll-gate-cash-management.component';
import { TollgateReceiptPrintComponent } from "@components/aftc-digitalization/Tollgate/tollgate-receipt-print/tollgate-receipt-print-component";
import { TollGateNavbarComponent } from "@components/aftc-digitalization/Tollgate/toll-gate-navbar/toll-gate-navbar.component";
import { PreprocessingDepositComponent } from "@components/aftc-digitalization/preprocessing/preprocessing-deposit/preprocessing-deposit-component";
import { PreprocessingDeliveryComponent } from "@components/aftc-digitalization/preprocessing/preprocessing-delivery/preprocessing-delivery-component";
import { PreprocessingDepositPrintComponent } from "@components/aftc-digitalization/preprocessing/preprocessing-deposit-print/preprocessing-deposit-print.component";
import { PreprocessingDeliveryPrintComponent } from "@components/aftc-digitalization/preprocessing/preprocessing-delivery-print/preprocessing-delivery-print.component";
import {
  ColdStorageDepositPrintComponent
} from "@components/aftc-digitalization/Cold-Storage/cold-storage-deposit-print/cold-storage-deposit-print.component";
import {
  ColdStorageDepositEntryFormComponent
} from "@components/aftc-digitalization/Cold-Storage/cold-storage-deposit/cold-storage-deposit-entry-form.component";
import {
  ColdStorageDeliveryEntryFormComponent
} from "@components/aftc-digitalization/Cold-Storage/cold-storage-delivery/cold-storage-delivery-entry-form.component";
import {
  ColdStorageDeliveryPrintComponent
} from "@components/aftc-digitalization/Cold-Storage/cold-storage-delivery-print/cold-storage-delivery-print.component";
import {
  PackingDeliveryComponent
} from "@components/aftc-digitalization/Packing/packing-delivery/packing-delivery-component";
import {
  PackingDepositComponent
} from "@components/aftc-digitalization/Packing/packing-deposit/packing-deposit-component";
import {
  PackingDepositPrintComponent
} from "@components/aftc-digitalization/Packing/packing-deposit-print/packing-deposit-print.component";
import {
  PackingDeliveryPrintComponent
} from "@components/aftc-digitalization/Packing/packing-delivery-print/packing-delivery-print.component";
import { StackHolderDetailsComponent } from '@components/aftc-digitalization/stack-holder-details/stack-holder-details.component';
import { StackDetailsComponent } from '@components/aftc-digitalization/stack-details/stack-details.component';
import { StockDetailsViewComponent } from '@components/aftc-digitalization/stock-details-view/stock-details-view.component';
import { UnitViewComponent } from "@components/aftc-digitalization/Warehouse/unit-view/unit-view.component";
import { DepositUnitEntryComponent } from "@components/aftc-digitalization/Warehouse/deposit-unit-entry/deposit-unit-entry.component";
import {
  WarehouseUnitNavbarComponent
} from "@components/aftc-digitalization/Warehouse/warehouse-unit-navbar/warehouse-unit-navbar.component";
import {
  DeliveryUnitEntryComponent
} from "@components/aftc-digitalization/Warehouse/delivery-unit-entry/delivery-unit-entry.component";
import {
  WeighBridgeNavbarComponent
} from "@components/aftc-digitalization/weigh-bridge/weigh-bridge-navbar/weigh-bridge-navbar.component";
import {
  WeighBridgeViewComponent
} from "@components/aftc-digitalization/weigh-bridge/weigh-bridge-view/weigh-bridge-view.component";
import {
  WeighBridgePrintComponent
} from "@components/aftc-digitalization/weigh-bridge/weigh-bridge-print/weigh-bridge-print.component";
import {
  WeighBridgeCashManagementComponent
} from "@components/aftc-digitalization/weigh-bridge/weigh-bridge-cash-management/weigh-bridge-cash-management.component";
import {
  WeighBridgeDetailViewComponent
} from "@components/aftc-digitalization/weigh-bridge/weigh-bridge-detail-view/weigh-bridge-detail-view.component";
import {
  WeighBridgeCashOverallPrintComponent
} from "@components/aftc-digitalization/weigh-bridge/weigh-bridge-cash-overall-print/weigh-bridge-cash-overall-print.component";
import { DeliveryRequestViewComponent } from "@components/aftc-digitalization/Cold-Storage/delivery-request-view/delivery-request-view.component";
import { StockManagementComponent } from "@components/aftc-digitalization/Cold-Storage/stock-management/stock-management.component";
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SolarTunnelDryerDeliveryEntryFormComponent } from '@components/aftc-digitalization/Solar-Tunnel-Dryer/solar-tunnel-dryer-delivery/solar-tunnel-dryer-delivery-entry-form.component';
import { SolarTunnelDryerDeliveryPrintComponent } from '@components/aftc-digitalization/Solar-Tunnel-Dryer/solar-tunnel-dryer-delivery-print/solar-tunnel-dryer-delivery-print.component';
import { SolarTunnelDryerDepositEntryFormComponent } from '@components/aftc-digitalization/Solar-Tunnel-Dryer/solar-tunnel-dryer--deposit/solar-tunnel-dryer-deposit-entry-form.component';
import { SolarTunnelDryerDepositPrintComponent } from '@components/aftc-digitalization/Solar-Tunnel-Dryer/solar-tunnel-dryer--deposit-print/solar-tunnel-dryer-deposit-print.component';
import { AddNewMembersDetailsComponent } from '@components/impact-study/add-new-members/add-new-members-component';
import { DeliveryRequestViewDetailsComponent } from '@components/aftc-digitalization/Cold-Storage/delivery-request-view-details/delivery-request-view-details.component';
import { DepositorViewComponent } from '@components/aftc-digitalization/Cold-Storage/depositor-view/depositor-view.component';
import { ColdStorageCreditScreenComponent } from '@components/aftc-digitalization/Cold-Storage/cold-storage-credit-screen/cold-storage-credit-screen.component';
import { DepositRequestViewComponent } from '@components/aftc-digitalization/Cold-Storage/deposit-request-view/deposit-request-view.component';
import { CommodityDetailsViewComponent } from '@components/aftc-digitalization/Cold-Storage/commodity-details-view/commodity-details-view.component';
import { ColdStorageDepositCasedPrintComponent } from '@components/aftc-digitalization/Cold-Storage/cold-storage-deposit-case-print/cold-storage-deposit-case-print.component';
import { ColdStorageDeliveryCasedPrintComponent } from '@components/aftc-digitalization/Cold-Storage/cold-storage-delivery-case-print/cold-storage-delivery-case-print.component';
import { FrontOfficeEntryFormComponent } from '@components/aftc-digitalization/front-office-entry/front-office-entry-form.component';
import { TollGateNewEntryFormComponent } from '@components/aftc-digitalization/Tollgate/toll-gate-new-entry/tollgate-new-entry-form.component';

defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesComponent,
    NotificationsComponent,
    UserComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    LanguageComponent,
    MainMenuComponent,
    SubMenuComponent,
    MenuItemComponent,
    ControlSidebarComponent,
    SidebarSearchComponent,
    TollGateFormComponent,
    ProductsComponent,
    EmployeeDetailsComponent,
    EntryListComponent,
    RenterDetailsComponent,
    WeightBridgeEntryComponent,
    TollGatePrintScreenComponent,
    PrintViewComponent,
    FrontOfficeComponent,
    AftcReportEntry,
    AFTCProductComponent,
    AFTCPrintComponent,
    AFTCEditComponent,
    AFTCAddProductComponent,
    AFTCShowComponent,
    AFTCViewComponent,
    WarehouseDepositEntryFormComponent,
    WarehouseDeliveryEntryFormComponent,
    PendingTestComponent,
    CameraRecordsComponent,
    AftcTechAnalystComponent,
    StockDetails,
    ImpactDataView,
    ImpactDashboard,
    ImPactStudy,
    NewsFeedAdminComponent,
    NewsFeedViewComponent,
    AftcMembershipView,
    AftcMembershipForm,
    BasicAftcMembershipForm,
    SabpaisaWrapperComponent,
    DepotEntryForm,
    AftcDepotView,
    depotVehicleDataView,
    AdminVehicleDataView,
    VehiclePunchDataView,
    BasicAftcTestingMembershipForm,
    AftcTestingMembershipForm,
    WarehouseDeliveryPrintComponent,
    WarehouseDepositPrintComponent,
    TollGateViewComponent,
    TollGateOverallPrintComponent,
    TollGateCashManagementComponent,
    TollgateReceiptPrintComponent,
    TollGateNavbarComponent,
    PreprocessingDepositComponent,
    PreprocessingDeliveryComponent,
    PreprocessingDepositPrintComponent,
    PreprocessingDeliveryPrintComponent,
    ColdStorageDepositPrintComponent,
    ColdStorageDepositEntryFormComponent,
    ColdStorageDeliveryEntryFormComponent,
    ColdStorageDeliveryPrintComponent,
    PackingDeliveryComponent,
    PackingDepositComponent,
    PackingDepositPrintComponent,
    PackingDeliveryPrintComponent,
    StackHolderDetailsComponent,
    StackDetailsComponent,
    StockDetailsViewComponent,
    UnitViewComponent,
    DepositUnitEntryComponent,
    DeliveryUnitEntryComponent,
    WarehouseUnitNavbarComponent,
    WeighBridgeNavbarComponent,
    WeighBridgeViewComponent,
    WeighBridgePrintComponent,
    WeighBridgeCashManagementComponent,
    WeighBridgeDetailViewComponent,
    WeighBridgeCashOverallPrintComponent,
    DeliveryRequestViewComponent,
    StockManagementComponent,
    SolarTunnelDryerDeliveryEntryFormComponent,
    SolarTunnelDryerDeliveryPrintComponent,
    SolarTunnelDryerDepositEntryFormComponent,
    SolarTunnelDryerDepositPrintComponent,
    AddNewMembersDetailsComponent,
    DeliveryRequestViewDetailsComponent,
    DepositorViewComponent,
    ColdStorageCreditScreenComponent,
    DepositRequestViewComponent,
    CommodityDetailsViewComponent,
    ColdStorageDepositCasedPrintComponent,
    ColdStorageDeliveryCasedPrintComponent,
    FrontOfficeEntryFormComponent,
    TollGateNewEntryFormComponent
  ],
  imports: [

    NgbAlertModule,
    NgbDatepickerModule,
    FormsModule,
    NgbDropdownModule,
    NgbModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    BrowserModule,
    StoreModule.forRoot({ auth: authReducer, ui: uiReducer }),
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    ProfabricComponentsModule,
    MatTooltipModule,
    NgbDropdownModule,
    NgxSpinnerModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng2SearchPipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    PrintDataService,
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [ConfigService],
      multi: true,
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {
}
