import { ApiService } from "@services/api.service";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { depotDetails } from "@/model/depotDetails/depotDetails";
import { CommodityDetails } from "@/model/commodityDetails/CommodityDetails";

@Component({
  selector: "app-front-office-entry-form",
  templateUrl: "./front-office-entry-form.component.html",
  styleUrls: ["./front-office-entry-form.component.css"]
})
export class FrontOfficeEntryFormComponent implements OnInit {
  Purposes: string[] = ["Loading", "Un Loading"];
  selectedPurpose: string = "Loading";
  todayDate: any;
  datepipe: any;
  heavyForm: any;
  vForm: any;
  aForm: any;
  qrCodeGenerate = false;
  vehicleNumber: string;
  currentTollGate: TollGate | undefined;
  vehicleOption: string[] = [];
  wheelOptions: number[] = [];
  heavy = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  serviceType = false;
  userType: string;
  futureDateDisable: string | null;
  showFields: number;
  loadType: boolean = false;
  allMemberDetails: depotDetails[] = [];
  currentMemberDetails: depotDetails[] = [];
  currentMemberNames: string[] = [];
  commodityDetails: CommodityDetails[] = [];
  commodityNames: string[] = [];
  showRefNumber: boolean = false;
  dropdownList = [];
  selectedItem = this.dropdownList;
  currentYearsValues: string = "";
  currentIrmNo: string = "";
  irmIsAlreadyExist: boolean = false;
  dropdownSettings = {};
  irmNoError: string = "";
  storageField = false;
  serviceField = false;
  storageType: string;
  tollgateSeq: number = 0;
  constructor(private apiService: ApiService, datepipe: DatePipe,
    private spinner: NgxSpinnerService, private router: Router,
    private translate: TranslateService, private toaster: ToastrService) {
    this.datepipe = datepipe;
    this.todayDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.futureDateDisable = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm');
    this.initial_data();
    this.translate.addLangs(['en', 'ta']);
    this.translate.setDefaultLang('en'); // Default language
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No data available',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      allowRemoteDataSearch: false,
      itemsShowLimit: 3,
      lazyLoading: false,
      selectAllText: '',
      unSelectAllText: '',
    };
  }

  gateForm = new UntypedFormGroup({
    tollgateSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    memberName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    tollgateType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    tollgateTypeSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehEntryDateTime: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehExitDateTime: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehCost: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    renterSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    renterEntryName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    commodityName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    weightMt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    bagsUnits: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    consignorSupplierName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    placeDispatchOrigin: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    deliveryWayBill: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    declarationDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    purpose: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    charges: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    renterAgentName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    securityClerk: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    passPrice: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    reportQr: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    isActive: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    modifiedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    tollgateVehicleDetails: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleQr: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    wheelCount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    driverName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    driverPhNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehInfo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehNumberPlate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehState: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    cashDeposit: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    modeOfPayment: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depotId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    refNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    irmNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleTypeSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
  });

  ngOnInit(): void {
    this.userType = localStorage.getItem("role");
    this.getMemberDetails();
    this.getCommodityDetails();
    this.initial_data();
    this.getFiscalYear();
    Object.keys(this.gateForm.controls).forEach((controlName) => {
      if (controlName !== "serviceType") {
        this.gateForm.get(controlName).disable();
      }
    });
  }



  getFiscalYear() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const startYear = month < 3 ? year - 1 : year;
    const endYear = startYear + 1;

    this.currentYearsValues = `${startYear}-${endYear.toString().slice(-2)}`;
  }

  // Get all member details
  getMemberDetails() {
    this.spinner.show();
    this.apiService.getDepotDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.spinner.hide();
        this.allMemberDetails = data.filter(item => item.records == "NEW");
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error(err.error.message || "Member details not getting, Please try again later");
      });
  }

  // Get all commodity details
  getCommodityDetails() {
    this.apiService.getCommodityDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.commodityDetails = data;
        const set = new Set(this.commodityDetails.map(item => item.commodityName));
        this.commodityNames = Array.from(set).sort((a, b) => a.localeCompare(b));
      }, (err: any) => {
        this.toaster.error(err.error.message || "Commodity details not getting, Please try again later");
      });
  }

  // Get current Date
  initial_data() {
    const now = new Date();
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
    this.gateForm.controls["vehEntryDateTime"].setValue(formattedDateTime);
    this.gateForm.controls["vehState"].setValue("Tamil Nadu");
  }

  // Field conditions based on selection of service type
  selectServiceType(event: any) {
    this.vehicleOption = [];
    this.currentMemberDetails = [];
    this.currentMemberNames = [];
    const value = event.target.value;
    if (value == "Warehouse") {
      this.gateForm.enable();
      this.gateForm.controls["charges"].disable();
      this.vehicleOption.push("Auto");
      this.vehicleOption.push("Van");
      this.vehicleOption.push("Truck");
      this.gateForm.controls["vehType"].setValue("");
      this.gateForm.controls["weightMt"].setValue("");
      this.gateForm.controls["purpose"].setValue("");
      // this.showFields = 2;
    } else if (value == "Depot") {
      this.gateForm.enable();
      this.gateForm.controls["charges"].disable();
      this.vehicleOption.push("Auto");
      this.vehicleOption.push("Van");
      this.vehicleOption.push("Truck");
      this.gateForm.controls["vehType"].setValue("");
      this.gateForm.controls["weightMt"].setValue("");
      this.gateForm.controls["purpose"].setValue("");
      // this.showFields = 1;
    }
    this.showFields = 1;
    this.currentMemberDetails = this.allMemberDetails.sort((a, b) => a.depotOccupantName.localeCompare(b.depotOccupantName));
    this.dropdownList = this.currentMemberDetails.map(member => {
      var returnValue = {};
      if (member.depotNo && member.slDepotNo) {
        returnValue = { item_id: member.depotDetailsId, item_text: member.depotOccupantName + ' / ' + member.depotNo + ' - SL ' + member.slDepotNo }
      } else if (member.depotNo) {
        returnValue = { item_id: member.depotDetailsId, item_text: member.depotOccupantName + ' / ' + member.depotNo }
      } else if (member.slDepotNo) {
        returnValue = { item_id: member.depotDetailsId, item_text: member.depotOccupantName + ' / SL ' + member.slDepotNo }
      } else {
        returnValue = { item_id: member.depotDetailsId, item_text: member.depotOccupantName }
      }
      return returnValue;
    });
  }

  selectMember(event) {
    if (this.showFields < 2) {
      this.showFields = 2
      console.log('selectMember', event.target.value);
    }
  }

  // Number only acceptable condition
  OnlyNumbers($event) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "ArrowRight", "ArrowLeft"];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
  }

  formErrorValidation = {
    memberName: "",
    commodityName: "",
    vehNumberPlate: "",
    vehEntryTime: "",
    vehType: "",
    wheelCount: "",
    serviceType: "",
    printType: "",
    purpose: "",
    weightMt: "",
    refNumber: "",
    irmNo: "",
  };

  // Required field validation conditions
  formValidation(form: any) {
    this.formErrorValidation.memberName = "";
    this.formErrorValidation.commodityName = "";
    this.formErrorValidation.vehEntryTime = "";
    this.formErrorValidation.vehNumberPlate = "";
    this.formErrorValidation.vehType = "";
    this.formErrorValidation.wheelCount = "";
    this.formErrorValidation.serviceType = "";
    this.formErrorValidation.printType = "";
    this.formErrorValidation.purpose = "";
    this.formErrorValidation.weightMt = "";
    this.formErrorValidation.refNumber = "";
    this.formErrorValidation.irmNo = "";
    let hasError = false;

    if (this.gateForm.get("vehType").value == "Truck") {
      if (this.gateForm.get("purpose").invalid) {
        this.formErrorValidation.purpose = "Purpose is Required";
        hasError = true;
      }

      if (this.gateForm.get("purpose").value == "Unloading" && this.gateForm.get("weightMt").invalid) {
        this.formErrorValidation.weightMt = "Weight MT is Required";
        hasError = true;
      }

      if (this.gateForm.get("purpose").value == "Unloading" && this.gateForm.get("commodityName").invalid) {
        this.formErrorValidation.commodityName = "Commodity Name is Required";
        hasError = true;
      }
    }

    if (this.gateForm.get("vehNumberPlate").invalid) {
      this.formErrorValidation.vehNumberPlate = "Vehicle Number is Required";
      hasError = true;
    }

    if (this.gateForm.get("memberName").invalid) {
      this.formErrorValidation.memberName = "Member Name is Required";
      hasError = true;
    }

    if (this.gateForm.get("vehEntryDateTime").invalid) {
      this.formErrorValidation.vehEntryTime = "Vehicle Entry Time is Required";
      hasError = true;
    }

    if (this.gateForm.get("vehType").invalid) {
      this.formErrorValidation.vehType = "Vehicle Type is Required";
      hasError = true;
    }

    if (this.gateForm.get("wheelCount").invalid) {
      this.formErrorValidation.wheelCount = "Wheel Count is Required";
      hasError = true;
    }

    if (this.gateForm.get("serviceType").invalid) {
      this.formErrorValidation.serviceType = "Service Type is Required";
      hasError = true;
    }

    if (this.gateForm.get("modeOfPayment").value === 'Bank') {
      if (this.gateForm.get('refNumber')?.invalid) {
        this.formErrorValidation.refNumber = 'Reference Number is Required';
        hasError = true;
      }
    }

    if (this.gateForm.get('irmNo')?.invalid) {
      this.formErrorValidation.irmNo = 'IRM No is Required';
      hasError = true;
    }

    if (!hasError) {
      if (this.irmIsAlreadyExist) {
        this.irmNoError = "IRM No is already exists"
      } else {
        this.saveForm();
      }
    }
  }

  // Wheels count set based on selected vehicle type
  selectVehicleType(type: string) {
    this.gateForm.controls['vehicleTypeSeq'].setValue("")
    const value = type;
    this.wheelOptions = [];
    var wheel: number[] = [];
    if (value == "Auto") {
      wheel.push(Number(3));
      this.gateForm.controls["wheelCount"].setValue(Number(3));
      this.gateForm.controls["charges"].setValue(Number(5));
      this.gateForm.controls["modeOfPayment"].setValue('Cash on Hand');
      this.heavy = false;
      this.showFields = 8;
      this.gateForm.controls['vehicleTypeSeq'].setValue(this.currentYearsValues + '/A/')
    } else if (value == "Van") {
      wheel.push(Number(4));
      this.gateForm.controls["wheelCount"].setValue(Number(4));
      this.gateForm.controls["charges"].setValue(Number(10));
      this.gateForm.controls["modeOfPayment"].setValue('Cash on Hand');
      this.heavy = false;
      this.showFields = 8;
      this.gateForm.controls['vehicleTypeSeq'].setValue(this.currentYearsValues + '/V/')
    } else if (value == "Truck") {
      wheel.push(Number(6));
      wheel.push(Number(10));
      wheel.push(Number(12));
      wheel.push(Number(14));
      this.gateForm.controls["wheelCount"].setValue("");
      this.heavy = true;
      this.showFields = 3;
      this.gateForm.controls['vehicleTypeSeq'].setValue(this.currentYearsValues + '/L/')
    }
    this.wheelOptions = wheel;
  }

  onCheckIrmNo(event: any) {
    const value = event.target.value;
    this.currentIrmNo = this.gateForm.get('vehicleTypeSeq').value + value;
    this.checkIrmNumber();
  }

  checkIrmNumber() {
    const JsonData = {
      irmNo: this.currentIrmNo
    }
    this.apiService.checkIrmNumber(JsonData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.irmIsAlreadyExist = false;
        this.irmNoError = ""
      }, (error: any) => {
        this.irmIsAlreadyExist = true;
        console.log(error.statusCode)
        this.irmNoError = "IRM No is already exists"
      })
  }

  // When change the wheel again select the purpose field
  wheelCountFunction(event: any) {
    this.gateForm.controls["purpose"].setValue("");
    this.gateForm.controls["charges"].setValue("");
    if (this.showFields < 4) {
      this.showFields = 4;
    }
  }

  // Charges calculation based on purpose
  chargeCal(event: any) {
    const value = event.target.value;
    if (value == "Loading") {
      if (this.gateForm.get("wheelCount").value == Number(6)) {
        this.gateForm.controls["charges"].setValue(Number(150));
      } else if (this.gateForm.get("wheelCount").value > Number(6)) {
        this.gateForm.controls["charges"].setValue(Number(200));
      }
      this.gateForm.controls["weightMt"].setValue("");
      this.gateForm.controls["weightMt"].disable();
      this.loadType = false;
    } else if (value == "Unloading") {
      const weightValue = this.gateForm.get("weightMt").value;
      const weight = Math.round(weightValue);
      if (this.gateForm.get("vehState").value == "Tamil Nadu") {
        const charge = weight * Number(15);
        if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
          this.gateForm.controls["charges"].setValue(Number(150));
        } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
          this.gateForm.controls["charges"].setValue(weight * Number(15));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
          this.gateForm.controls["charges"].setValue(Number(200));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
          this.gateForm.controls["charges"].setValue(weight * Number(15));
        }
      } else {
        const charge = weight * Number(30);
        if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
          this.gateForm.controls["charges"].setValue(Number(150));
        } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
          this.gateForm.controls["charges"].setValue(weight * Number(30));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
          this.gateForm.controls["charges"].setValue(Number(200));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
          this.gateForm.controls["charges"].setValue(weight * Number(30));
        }
      }
      this.gateForm.controls["weightMt"].enable();
      this.loadType = true;
    }
    if (this.showFields < 5) {
      this.showFields = 8;
      this.gateForm.controls["modeOfPayment"].setValue('Cash on Hand');
    }
  }

  // Charges calculation based on state
  changeState(event: any) {
    const value = event.target.value;
    const weight = Math.round(this.gateForm.get("weightMt").value);
    if (this.gateForm.get("purpose").value == "Unloading") {
      if (value == "Tamil Nadu") {
        const charge = weight * Number(15);
        if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
          this.gateForm.controls["charges"].setValue(Number(150));
        } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
          this.gateForm.controls["charges"].setValue(weight * Number(15));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
          this.gateForm.controls["charges"].setValue(Number(200));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
          this.gateForm.controls["charges"].setValue(weight * Number(15));
        }
      } else {
        const charge = weight * Number(30);
        if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
          this.gateForm.controls["charges"].setValue(Number(150));
        } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
          this.gateForm.controls["charges"].setValue(weight * Number(30));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
          this.gateForm.controls["charges"].setValue(Number(200));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
          this.gateForm.controls["charges"].setValue(weight * Number(30));
        }
      }
    }
    if (this.showFields < 6) {
      this.showFields = 6;
    }
  }

  // Charges calculation based on weight
  calculateCharges(event: any) {
    const value = event.target.value;
    const weight = Math.round(value);
    console.log(weight);
    if (this.gateForm.get("vehState").value == "Tamil Nadu") {
      const charge = weight * Number(15);
      if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
        this.gateForm.controls["charges"].setValue(Number(150));
      } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
        this.gateForm.controls["charges"].setValue(weight * Number(15));
      } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
        this.gateForm.controls["charges"].setValue(Number(200));
      } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
        this.gateForm.controls["charges"].setValue(weight * Number(15));
      }
    } else {
      const charge = weight * Number(30);
      if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
        this.gateForm.controls["charges"].setValue(Number(150));
      } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
        this.gateForm.controls["charges"].setValue(weight * Number(30));
      } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
        this.gateForm.controls["charges"].setValue(Number(200));
      } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
        this.gateForm.controls["charges"].setValue(weight * Number(30));
      }
    }
    if (this.showFields < 8) {
      this.showFields = 8;
    }
  }

  onSelectPaymentType(event: any) {
    const value = event.target.value;
    if (value == "Cash on Hand") {
      this.showRefNumber = false;
    } else {
      this.showRefNumber = true;
    }
  }

  // Save tollgate details
  saveForm() {
    this.spinner.show();
    this.gateForm.controls["charges"].enable();
    const entryDate = this.gateForm.get("vehEntryDateTime").value;
    console.log(entryDate)
    const epochTimestamp = new Date(entryDate).getTime() / 1000;
    this.gateForm.controls["vehEntryDateTime"].setValue(epochTimestamp);
    const declarationDate = this.gateForm.get("declarationDate").value;
    const epochTimeDeclarationDate = new Date(declarationDate).getTime() / 1000;
    this.gateForm.controls["declarationDate"].setValue(epochTimeDeclarationDate);
    this.gateForm.controls["createdAt"].setValue(Math.floor(new Date().getTime() / 1000));
    const tollGateDetails = this.gateForm.value;
    this.gateForm.controls["cashDeposit"].setValue(false);

    // For nested json combined the tollgate & vehicle details
    const combinedData = {
      tollgateSeq: tollGateDetails.tollgateSeq,
      tollgateType: tollGateDetails.tollgateType,
      memberName: tollGateDetails.memberName[0].item_text,
      depotId: tollGateDetails.memberName[0].item_id,
      commodityName: tollGateDetails.commodityName,
      tollgateTypeSeq: tollGateDetails.tollgateTypeSeq,
      vehEntryDateTime: tollGateDetails.vehEntryDateTime,
      vehExitDateTime: tollGateDetails.vehExitDateTime,
      vehCost: tollGateDetails.vehCost,
      renterSeq: tollGateDetails.renterSeq,
      renterEntryName: tollGateDetails.renterEntryName,
      serviceType: tollGateDetails.serviceType,
      weightMt: tollGateDetails.weightMt,
      purpose: tollGateDetails.purpose,
      charges: tollGateDetails.charges,
      renterAgentName: tollGateDetails.renterAgentName,
      securityClerk: tollGateDetails.securityClerk,
      passPrice: tollGateDetails.passPrice,
      reportQr: tollGateDetails.reportQr,
      active: tollGateDetails.active,
      createdAt: tollGateDetails.createdAt,
      modifiedAt: tollGateDetails.modifiedAt,
      modeOfPayment: tollGateDetails.modeOfPayment,
      refNumber: tollGateDetails.refNumber,
      irmNo: this.currentIrmNo,
      tollgateVehicleDetails: {
        vehicleSeq: tollGateDetails.vehicleSeq,
        vehicleQr: tollGateDetails.vehicleQr,
        wheelCount: tollGateDetails.wheelCount,
        driverName: tollGateDetails.driverName,
        driverPhNo: tollGateDetails.driverPhNo,
        vehInfo: tollGateDetails.vehInfo,
        vehNumberPlate: tollGateDetails.vehNumberPlate.toUpperCase(),
        vehState: tollGateDetails.vehState,
        vehType: tollGateDetails.vehType
      }
    };
    console.log(combinedData);

    if (combinedData.vehEntryDateTime.toString().length == 10) {
      console.log(combinedData.vehEntryDateTime.toString().length);
      this.apiService.saveFrontOfficeVehicleEntry(combinedData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.spinner.hide();
          this.currentTollGate = data;
          this.tollgateSeq = data.tollgateSeq;
          this.serviceType = true;
          this.storageField = true;
          this.toaster.success("Vehicle entry saved successfully");
          this.gateForm.reset();
        }, (error: any) => {
          this.spinner.hide();
          this.serviceType = false;
          this.storageField = false;
          this.toaster.error(error.error.message || "something went wrong, Please try again...");
        });
    } else {
      this.toaster.error("Something went wrong, time not get properly. Please try again");
      location.reload();
    }

  }


  serviceClose() {
    if (this.gateForm.get("storageType").value != "") {
      if (this.gateForm.get("serviceType").invalid) {
        this.toaster.error("Please select service type");
      } else {
        if (this.gateForm.get("serviceType").value == "Deposit") {
          this.serviceType = false;
          sessionStorage.setItem("tollgateSeqNo", String(this.tollgateSeq));
          sessionStorage.setItem("serviceType", this.gateForm.get("serviceType").value);

          switch (this.storageType) {
            case "Common Warehouse": {
              this.router.navigate(["/main/depositEntry"]);
              break;
            }
            case "SLCM": {
              this.router.navigate(["/main/depositEntry"]);
              break;
            }
            case "Pre Processing": {
              this.router.navigate(["/main/preprocessing-deposit"]);
              break;
            }
            case "Cold Storage": {
              this.router.navigate(["/main/cold-storage-deposit"]);
              break;
            }
            case "Packing": {
              this.router.navigate(["/main/packing-deposit"]);
              break;
            }
            case "Solar Tunnel Dryer": {
              this.router.navigate(["/main/solar-tunnel-dryer-deposit"]);
              break;
            }
          }
        } else if (this.gateForm.get("serviceType").value == "Delivery") {
          this.serviceType = false;
          sessionStorage.setItem("tollgateSeqNo", String(this.tollgateSeq));
          sessionStorage.setItem("serviceType", this.gateForm.get("serviceType").value);

          switch (this.storageType) {
            case "Common Warehouse": {
              this.router.navigate(["/main/deliveryEntry"]);
              break;
            }
            case "SLCM": {
              this.router.navigate(["/main/deliveryEntry"]);
              break;
            }
            case "Pre Processing": {
              this.router.navigate(["/main/preprocessing-delivery"]);
              break;
            }
            case "Cold Storage": {
              this.router.navigate(["/main/cold-storage-delivery"]);
              break;
            }
            case "Packing": {
              this.router.navigate(["/main/packing-delivery"]);
              break;
            }
            case "Solar Tunnel Dryer": {
              this.router.navigate(["/main/solar-tunnel-dryer-delivery"]);
              break;
            }
          }
        }
      }
    }
  }

  storageClose() {
    const StorageType = this.gateForm.get("storageType").value;
    if (StorageType.invalid) {
      this.toaster.error("Please select storage type");
    } else if (StorageType != "") {
      sessionStorage.setItem("storageType", this.gateForm.get("storageType").value);
      this.storageType = this.gateForm.get("storageType").value;
      if (StorageType === 'Pre Processing' || StorageType === 'Packing' || StorageType === 'Solar Tunnel Dryer') {
        this.gateForm.controls["serviceType"].setValue("Deposit");
        this.serviceClose();
      } else {
        if (this.currentTollGate.purpose === 'Loading') {
          this.gateForm.controls["serviceType"].setValue("Delivery");
          this.serviceClose();
        } else if (this.currentTollGate.purpose === 'Unloading') {
          this.gateForm.controls["serviceType"].setValue("Deposit");
          this.serviceClose();
        } else {
          this.storageField = false;
          this.serviceField = true;
        }
      }
    }
  }

  cancel() {
    this.serviceType = false;
    this.storageField = false;
    this.serviceField = false;
    sessionStorage.removeItem("storageType");
    sessionStorage.removeItem("tollgateSeqNo");
    sessionStorage.removeItem("serviceType");
    this.router.navigate(["/main/frontOffice"]);
  }

  switchLanguage(lang: string) {
    this.translate.use(lang);
  }


  ngOnDestroy(): void {
    this.translate.use('en');
  }
}



