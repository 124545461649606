import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ColdStorageBuildingDetails, ColdStorageGetDetails } from "@/model/depotDetails/depotDetails";


@Component({
  selector: "add-new-members-entry-form",
  templateUrl: "./add-new-members-component.html",
  styleUrls: ["./add-new-members-component.scss"]
})
export class AddNewMembersDetailsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  dropdownList = [];
  options: any[] = [];
  depotNumberList = [];
  slDepotNumberList = [];
  coldStorageList : ColdStorageGetDetails [] = [];
  getAllColdStorageList : ColdStorageGetDetails [] = [];
  selectedItems = this.dropdownList;
  selectedItemsDepotNumber = this.depotNumberList;
  selectedItemsSLDepotNumber = this.slDepotNumberList;
  selectedItemsColdStorageSpaceNumber = this.coldStorageList;
  dropdownSettings = {};
  ColdStorageSettings = {};
  selectedArrayType: any[] = [];
  selectedLabel: any[] = [];
  selectDepotNumbers: any[] = [];
  selectSLDepotNumbers: any[] = [];
  selectColdStorageSpaceNumbers: any[] = [];
  isRentalType: boolean = false;
  isSpaceNumber: boolean = false;
  isFullFloorRent: boolean = false;
  srpTimeout: any;
  isAlreadyUser: boolean = false;
  isShowDepositAmount: boolean = false;
  fullFloorList : string [] = [];
  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
    private router: Router, private toaster: ToastrService) {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "label",
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.ColdStorageSettings = {
      singleSelection: false,
      idField: "csBuildingId",
      textField: "spaceNumber",
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  depositDetailsForm = new UntypedFormGroup({
    depotDetailsId: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    testDepotNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depotNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depotOccupantName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    aftcMembershipType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    testServiceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    testSlDepotNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    slDepotNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    records: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    testSpaceNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    phoneNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    userName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    password: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    coldStorageRecords: new UntypedFormControl([], [Validators.required, Validators.nullValidator]),
    coldStorageFloorNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    coldStorageRentalType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    initialDepositAmount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    paymentModeThroughAftcAccount : new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
  });

  depositFormErrors = {
    depotOccupantName: "",
    // testDepotNo: "",
    // testSlDepotNo: "",
    aftcMembershipType: "",
    testServiceType: "",
    serviceType: "",
    phoneNumber: "",
    userName: "",
    password: "",
    coldStorageFloorNumber: "",
    coldStorageRentalType: "",
    testSpaceNumber: "",
    paymentModeThroughAftcAccount : "",
    initialDepositAmount: "",
  };

  ngOnInit() {
    this.depotNumberList = [];
    this.slDepotNumberList = [];
    this.dropdownList = [
      { id: 1, label: "Depots" },
      { id: 2, label: "Common Warehouse" },
      { id: 3, label: "Cold Storage Unit" },
      { id: 4, label: "Packaging Unit" },
      { id: 5, label: "Cleaning & Grading" },
      { id: 6, label: "R & D" },
      { id: 7, label: "Solar Tunnel Dryer" }
    ];
    for (let i = 1; i <= 256; i++) {
      this.depotNumberList.push({ id: i, label: `D:${i}` });
    }

    for (let i = 1; i <= 256; i++) {
      this.slDepotNumberList.push({ id: i, label: ` SL D:${i}` });
    }
    this.getColdStorageAvailabilityInfo();
  }

  getColdStorageAvailabilityInfo() {
    this.apiServices.getColdStorageAvailabilityInfo()
      .pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.getAllColdStorageList = res;
        this.coldStorageList = this.getAllColdStorageList.filter(item => item.isBooked === false);
        const values = new Set(this.coldStorageList.map(x => x.floorNumber));
        this.fullFloorList = Array.from(values);
        console.log(this.coldStorageList);
      }, (error: any) => {
        this.toaster.error(error.error.msg || "Something went wrong , please try again")
      });
  }

  formValidationDepot() {
    this.depositFormErrors.depotOccupantName = "";
    // this.depositFormErrors.testDepotNo = "";
    // this.depositFormErrors.testSlDepotNo = "";
    this.depositFormErrors.aftcMembershipType = "";
    this.depositFormErrors.testServiceType = "";
    this.depositFormErrors.userName = "";
    this.depositFormErrors.password = "";
    this.depositFormErrors.phoneNumber = "";
    this.depositFormErrors.coldStorageFloorNumber = "";
    this.depositFormErrors.coldStorageRentalType = "";
    this.depositFormErrors.testSpaceNumber = "";
    this.depositFormErrors.paymentModeThroughAftcAccount = "";
    let hasError = false;

    if (this.depositDetailsForm.get('depotOccupantName')?.invalid) {
      this.depositFormErrors.depotOccupantName = "* Depositor Name is required";
      this.depositDetailsForm.markAsTouched();
      hasError = true;
    }

    // if (this.depositDetailsForm.get('testDepotNo')?.invalid && this.depositDetailsForm.get('testSlDepotNo')?.invalid) {
    //   this.depositFormErrors.testDepotNo = "* Depot No is required";
    //   this.depositFormErrors.testSlDepotNo = "* SL Depot No is required";
    //   this.depositDetailsForm.markAsTouched();
    //   hasError = true;
    // } else {
    //   this.depositFormErrors.testDepotNo = "";
    //   this.depositFormErrors.testSlDepotNo = "";
    // }



    if (this.depositDetailsForm.get("aftcMembershipType").invalid) {
      this.depositFormErrors.aftcMembershipType = "* Membership Type is required";
      this.depositDetailsForm.markAsTouched();
      hasError = true;
    }
    if (this.depositDetailsForm.get('testServiceType')?.invalid) {
      this.depositFormErrors.testServiceType = "* Service Type is required";
      this.depositDetailsForm.markAsTouched();
      hasError = true;
    }
     
    if(this.selectedArrayType.find(item => item.label === 'Cold Storage Unit')){
      if(this.depositDetailsForm.get("userName").invalid){
        this.depositFormErrors.userName = "* User Name is required";
        this.depositDetailsForm.markAsTouched();
        hasError = true;
      }

      if(this.depositDetailsForm.get("password").invalid){
        this.depositFormErrors.password = "* Password is required";
        this.depositDetailsForm.markAsTouched();
        hasError = true;
      }

      if(this.depositDetailsForm.get('phoneNumber').invalid){
        this.depositFormErrors.phoneNumber = "* Phone Number is required";
        this.depositDetailsForm.markAsTouched();
        hasError = true;
      }

      if(this.depositDetailsForm.get('coldStorageRentalType').invalid) {
        this.depositFormErrors.coldStorageRentalType = "* Rental Type is required";
        this.depositDetailsForm.markAsTouched();
        hasError = true;
      }

      if(this.depositDetailsForm.get('coldStorageRentalType').value != 'OPEN_SPACE'){
      if(this.depositDetailsForm.get('coldStorageFloorNumber').invalid){
        this.depositFormErrors.coldStorageFloorNumber = "* Floor Number is required";
        this.depositDetailsForm.markAsTouched();
        hasError = true;
      }
      if(this.depositDetailsForm.get('testSpaceNumber').invalid){
        this.depositFormErrors.testSpaceNumber = "* Space Number is required";
        this.depositDetailsForm.markAsTouched();
        hasError = true;
      }
    }

    if(this.depositDetailsForm.get('paymentModeThroughAftcAccount')?.invalid){
      this.depositFormErrors.paymentModeThroughAftcAccount = "* Payment Type is required";
      this.depositDetailsForm.markAsTouched();
      hasError = true;
    }

    if(this.depositDetailsForm.get('paymentModeThroughAftcAccount').value === 'YES'){
      if(this.depositDetailsForm.get('initialDepositAmount').invalid){
        this.depositFormErrors.initialDepositAmount = "*Deposit Amount is required";
        this.depositDetailsForm.markAsTouched();
        hasError = true;
      }
    }

      // if(this.depositDetailsForm.get('coldStorageRentalType').value === 'SPACE'){
      //   if(this.depositDetailsForm.get('testSpaceNumber').invalid){
      //     this.depositFormErrors.testSpaceNumber = "* Space Number is required";
      //     this.depositDetailsForm.markAsTouched();
      //     hasError = true;
      //   }
      // }
    }

    if (!hasError) {
      if(!this.isAlreadyUser){
      this.saveDepotDetails();
      }else{
        this.depositFormErrors.userName = "* User Name Already Exists";
        this.depositDetailsForm.markAsTouched();
      }
    }
  }

  initial_data() {
    const now = new Date();
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
  }

  onItemDeSelect(item: any) {
    const index = this.selectedArrayType.findIndex((selectedItem: any) => selectedItem.id === item.id);
    if (index !== -1) {
      this.selectedArrayType.splice(index, 1);
    }
    console.log("selected", this.selectedArrayType)
    if (this.selectedArrayType.find(item => item.label === 'Cold Storage Unit')) {
      this.isRentalType = true;
      return
    } else {
      this.isRentalType = false;
    }
  }

  onItemSelect(items: any) {
    console.log("selected", this.selectedArrayType)
    this.selectedArrayType.push(items);
    if (this.selectedArrayType.find(item => item.label === 'Cold Storage Unit')) {
      this.isRentalType = true;
      return
    } else {
      this.isRentalType = false;
    }
  }

  onSelectAll(items: any) {
    this.selectedArrayType = [];
    this.selectedArrayType = items;
    if (this.selectedArrayType.find(item => item.label === 'Cold Storage Unit')) {
      this.isRentalType = true;
      return
    } else {
      this.isRentalType = false;
    }
  }

  onDeSelectAll(items: any) {
    this.selectedArrayType = [];
    if (this.selectedArrayType.find(item => item.label === 'Cold Storage Unit')) {
      this.isRentalType = true;
      return
    } else {
      this.isRentalType = false;
    }
  }

  selectedServiceType(event: any) {
    const value = event.target.value;
    this.selectedArrayType = value;
    if (this.selectedArrayType.find(item => item.label === 'Cold Storage Unit')) {
      this.isRentalType = true;
      return
    } else {
      this.isRentalType = false;
    }
  }

  onItemDeSelectDepotNumber(item: any) {
    const index = this.selectDepotNumbers.findIndex((selectedItemsDepotNumber: any) => selectedItemsDepotNumber.id === item.id);
    if (index !== -1) {
      this.selectDepotNumbers.splice(index, 1);
    }
  }

  onItemSelectDepotNumber(items: any) {
    this.selectDepotNumbers.push(items);
  }

  onSelectAllDepotNumber(items: any) {
    this.selectDepotNumbers = [];
    this.selectDepotNumbers = items;
  }

  selectedDepotNumber(event: any) {
    const value = event.target.value;
    this.selectDepotNumbers = value;
  }

  onDeSelectDepotNumberAll(item: any) {
    this.selectDepotNumbers = [];
  }

  onItemDeSelectSLDepotNumber(item: any) {
    const index = this.selectSLDepotNumbers.findIndex((selectedItemsSLDepotNumber: any) => selectedItemsSLDepotNumber.id === item.id);
    if (index !== -1) {
      this.selectSLDepotNumbers.splice(index, 1);
    }
  }

  onItemSelectSLDepotNumber(items: any) {
    this.selectSLDepotNumbers.push(items);
  }

  onSelectAllSLDepotNumber(items: any) {
    this.selectSLDepotNumbers = [];
    this.selectSLDepotNumbers = items;
  }

  onDeSelectSLDepotNumberAll(items: any) {
    this.selectSLDepotNumbers = [];
  }

  selectedSLDepotNumber(event: any) {
    const value = event.target.value;
    this.selectSLDepotNumbers = value;
  }

  onItemDeSelectSpaceNumber(item: any) {
    const index = this.selectColdStorageSpaceNumbers.findIndex((selectedItemsColdStorageSpaceNumber: any) =>
      selectedItemsColdStorageSpaceNumber.csBuildingId === item.csBuildingId);
    if (index !== -1) {
      this.selectColdStorageSpaceNumbers.splice(index, 1);
    }
  }

  onItemSelectSpaceNumber(items: any) {
    this.selectColdStorageSpaceNumbers.push(items);
  }

  onSelectAllSpaceNumber(items: any) {
    this.selectColdStorageSpaceNumbers = [];
    this.selectColdStorageSpaceNumbers = items;
    console.log(this.selectColdStorageSpaceNumbers);
  }


  selectedSpaceNumber(event: any) {
    const value = event.target.value;
    this.selectColdStorageSpaceNumbers = value;
  }

  onDeSelectSpaceNumberAll(items: any) {
    this.selectColdStorageSpaceNumbers = [];
  }

  selectRentalType(event: any) {
    this.isFullFloorRent = false;
    this.isSpaceNumber = false;
    const values = new Set(this.coldStorageList.map(x => x.floorNumber));
    this.fullFloorList = Array.from(values);
    const value = event.target.value;
    if(value == 'SPACE' || value == 'FLOOR'){
      this.isFullFloorRent = true;
      this.isSpaceNumber = false;
    } 
    // else if (value == 'FLOOR') {
    //   const values = new Set(this.getAllColdStorageList.map(x => x.floorNumber));
    //   const valuesNonFullyBooked = Array.from(values).find(floor => 
    //     !this.getAllColdStorageList.some(item => item.floorNumber === floor && item.isBooked !== false));
    //   this.fullFloorList = valuesNonFullyBooked ? [valuesNonFullyBooked] : Array.from(values);
    //   console.log(this.fullFloorList);
    //   this.isFullFloorRent = true;
    //   this.isSpaceNumber = false;
    // } 
    else if (value == 'OPEN_SPACE'){
      this.isFullFloorRent = false;
      this.isSpaceNumber = false;
    }
    this.depositDetailsForm.controls['coldStorageRentalType'].setValue(value);
    this.depositDetailsForm.controls['coldStorageFloorNumber'].setValue("");
  }

  selectFloorNumber(event: any) {
    this.selectColdStorageSpaceNumbers = [];
    this.depositDetailsForm.controls['testSpaceNumber'].setValue([]);
  const values = event.target.value;
  if(this.depositDetailsForm.get('coldStorageRentalType').value === 'SPACE' ||
   this.depositDetailsForm.get('coldStorageRentalType').value === 'FLOOR'){
    this.coldStorageList = this.getAllColdStorageList.filter(item => item.floorNumber === values && item.isBooked === false);
    this.isSpaceNumber = true;
  }else {
    this.coldStorageList = this.getAllColdStorageList.filter(item => item.isBooked === false);
    this.isSpaceNumber = false;
  }
  }

  OnlyNumbers($event) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkUserName(event: any) {
    if (this.srpTimeout) {
      clearTimeout(this.srpTimeout);
    }
    this.srpTimeout = setTimeout(() => {
      this.findExistingUsers(event.target.value);
      console.log(event.target.value);
    }, 1000);
  }

  onSelectPaymentType(event: any) {
  const value = event.target.value;
  if(value == 'YES'){
    this.isShowDepositAmount = true;
  }else{
    this.isShowDepositAmount = false;
  }
  }

  findExistingUsers(userName: string) {
    this.apiServices.findExistingUsers(userName)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.isAlreadyUser = false;
        this.depositFormErrors.userName = "";
      }, (err: any) => {
          this.depositFormErrors.userName = "* User Name Already Exists";
          this.depositDetailsForm.markAsTouched();
          this.isAlreadyUser = true

      });
  }

  saveDepotDetails() {
        this.spinner.show();
    if (this.selectDepotNumbers.length > 0) {
      const depotNumbers = this.selectDepotNumbers.map(item => item.label.split(':')[1]).join(',');
      const result = `D:${depotNumbers}`;
      this.depositDetailsForm.controls['depotNo'].setValue(result);
    } else {
      this.depositDetailsForm.controls['depotNo'].setValue('');
    }

    if (this.selectSLDepotNumbers.length > 0) {
      const slDepotNumbers = this.selectSLDepotNumbers.map(item => item.label.split(':')[1]).join(',');
      const result = `D:${slDepotNumbers}`;
      this.depositDetailsForm.controls['slDepotNo'].setValue(result);
    } else {
      this.depositDetailsForm.controls['slDepotNo'].setValue('');
    }
    this.depositDetailsForm.controls['records'].setValue('NEW');
    const result = this.selectedArrayType.map(item => item.label).join(', ');
    this.depositDetailsForm.controls['serviceType'].setValue(result);
    if (this.selectedArrayType.find(item => item.label === 'Cold Storage Unit')) {
    if(this.depositDetailsForm.get('coldStorageRentalType').value === 'SPACE'){
      const coldStorageRecords = this.selectColdStorageSpaceNumbers.map(item => ({
        csBuildingId: item.csBuildingId,
        rentingType: 'SPACE',
        isBooked: true,
        isActive: true,
      }));
      this.depositDetailsForm.controls['coldStorageRecords'].setValue(coldStorageRecords);
    }else if(this.depositDetailsForm.get('coldStorageRentalType').value === 'FLOOR'){
    //   const coldStorageRecords = [ {
    //     floorNumber: this.depositDetailsForm.get('coldStorageFloorNumber').value,
    //     rentingType: 'FLOOR'
    // } ]
    //   this.depositDetailsForm.controls['coldStorageRecords'].setValue(coldStorageRecords);
    const coldStorageRecords = this.selectColdStorageSpaceNumbers.map(item => ({
      csBuildingId: item.csBuildingId,
      rentingType: 'FLOOR',
      isBooked: true,
      isActive: true,
    }));
    this.depositDetailsForm.controls['coldStorageRecords'].setValue(coldStorageRecords);
    }
    }else if(this.depositDetailsForm.get('coldStorageRentalType').value === 'OPEN_SPACE') {
      this.depositDetailsForm.controls['coldStorageRecords'].setValue([]);
    }else{
      this.depositDetailsForm.controls['coldStorageRecords'].setValue([]);
      this.depositDetailsForm.controls['phoneNumber'].setValue(0);
      this.depositDetailsForm.controls['userName'].setValue("");
      this.depositDetailsForm.controls['password'].setValue("");
    }
    console.log("Save Depot Details", this.depositDetailsForm.value);
    this.apiServices.saveDepotDetails(this.depositDetailsForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.saveAlert();
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error( err.error.msg || "Something went wrong, Please try again later");
      });

  }


  saveAlert() {
    Swal.fire({
      title: "Details Saved Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then((result) => {
      window.location.reload();
    });
  }


}
