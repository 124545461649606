import { ApiService } from "@services/api.service";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { interval, Subject, Subscription, takeUntil } from "rxjs";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as QRCode from 'qrcode';

@Component({
  selector: "app-tollgate-receipt",
  templateUrl: "./tollgate-receipt-print-component.html",
  styleUrls: ["./tollgate-receipt-print-component.css"]
})
export class TollgateReceiptPrintComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  reportQr: string;
  currentGatePass: TollGate | undefined;
  currentDate: string;
  entryTime: string;
  entryDate: string;
  exitTime: string;
  private intervalSubscription: Subscription;
  tollgateView: string;
  declarationDate: number;
  qrCodeData: string = "https://aftc.varnik.cloud/#/qrEntry?code=";
  vehicleQrCodeUrl: string;

  constructor(private apiService: ApiService, private datePipe: DatePipe,
    private router: Router, private toaster: ToastrService) {
    this.currentDate = this.datePipe.transform(new Date(), "dd/MM/yyyy");
  }

  ngOnInit(): void {
    this.reportQr = sessionStorage.getItem("reportQr");
    this.tollgateView = sessionStorage.getItem("typeOfprint");
    this.getGatePass();
  }

  ngOnDestroy() {
    sessionStorage.removeItem("reportQr");
  }

  // Get tollgate detail by id for fetch data in receipt
  getGatePass() {
    this.apiService.getTollGate(this.reportQr)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.currentGatePass = data;
        this.declarationDate = data.declarationDate * 1000;
        const entryDate = new Date(data.vehEntryDateTime * 1000);
        const entryHours = entryDate.getHours().toString().padStart(2, "0");
        const entryMinutes = entryDate.getMinutes().toString().padStart(2, "0");
        const formattedEntryTime = `${entryHours}:${entryMinutes}`;
        this.entryTime = formattedEntryTime;

        const formattedDate = `${entryDate.getDate().toString().padStart(2, "0")}/${(entryDate.getMonth() + 1).toString().padStart(2, "0")}/${entryDate.getFullYear()}`;
        this.entryDate = formattedDate;

        if (data.vehExitDateTime != 0) {
          const exitDate = new Date(data.vehExitDateTime * 1000);
          const exitHours = exitDate.getHours().toString().padStart(2, "0");
          const exitMinutes = exitDate.getMinutes().toString().padStart(2, "0");
          const formattedExitTime = `${exitHours}:${exitMinutes}`;

          this.exitTime = formattedExitTime;
        }
        this.generateQRCode();
        // this.intervalSubscription = interval(1000).subscribe(() => {
        //   this.print();
        // });
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }


  generateQRCode() {
    const reportQrCodeData = this.qrCodeData + this.currentGatePass.reportQr;
    const vehicleQrCodeData = this.qrCodeData + this.currentGatePass.tollgateVehicleDetails.vehicleQr;

    this.generateQRCodeUrl(reportQrCodeData, vehicleQrCodeData)
      .then(() => {
      })
      .catch((error) => {
      });
  }

  generateQRCodeUrl(reportUrl: string, vehicleUrl: string): Promise<void> {
    return new Promise((resolve, reject) => {
      QRCode.toDataURL(vehicleUrl, (error, qrCodeDataUrl) => {
        if (error) {
          console.error('Error generating QR code:', error);
          reject(error);
        } else {
          this.vehicleQrCodeUrl = qrCodeDataUrl;
          console.log('QR Code Data URL:', qrCodeDataUrl);
          resolve();
          /*setInterval(() => this.print(), 1000);*/
          this.intervalSubscription = interval(1000).subscribe(() => {
            this.print();
          });
        }
      });
    });
  }

  // Receipt print function
  print() {
    const printStyle = "";
    `
      @media print {
        @page {
          size: A5; /* Set your desired page size here */
        }
      }
    `;
    const originalTitle = document.title;
    document.title = this.currentGatePass.tollgateVehicleDetails.vehNumberPlate;
    const style = document.createElement("style");
    style.innerHTML = printStyle;
    document.head.appendChild(style);
    window.print();
    document.head.removeChild(style);
    document.title = originalTitle;
    this.intervalSubscription.unsubscribe();

    setTimeout(() => {
      if (localStorage.getItem("frontOffice") == "true") {
        localStorage.removeItem("reportQr");
        localStorage.removeItem("frontOffice");
        this.router.navigate(["/main/tollgate-new-entry"]);
      } else if (this.tollgateView == "tollgateView") {
        localStorage.removeItem("reportQr");
        localStorage.removeItem("typeOfprint");
        this.router.navigate(["/main/tollGateView"]);
      } else {
        sessionStorage.removeItem("reportQr");
        this.router.navigate(["/main/tollgate-new-entry"]);
      }
    });
  }

}



