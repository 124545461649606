import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { CommodityDetails } from "@/model/commodityDetails/CommodityDetails";
import { ToastrService } from "ngx-toastr";
import { ColdStorageMaintenance } from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageMaintenance";
import { DatePipe } from "@angular/common";
import { ColdStorageCreditedDetails } from "@components/aftc-digitalization/aftc-digitalization-models/cold-storage-credited-details";


@Component({
  selector: "app-cold-storage-delivery-entry-form",
  templateUrl: "./cold-storage-delivery-entry-form.component.html",
  styleUrls: ["./cold-storage-delivery-entry-form.component.scss"]
})
export class ColdStorageDeliveryEntryFormComponent implements OnInit {
  getLocalPassDetails: TollGate[] = [];
  commodityDetails: CommodityDetails[] = [];
  commodityNames: string[] = [];
  getPassDetails: TollGate | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  depositMaintenance: ColdStorageMaintenance[] = [];
  listOfDepositApplicationNumber: string[] = [];
  depotId: number = 0;
  isShowFormDetails: boolean = false;
  balanceDetails: ColdStorageCreditedDetails;
  currentDepositDetails: ColdStorageMaintenance;
  currentAvailableBalance: number = 0;
  currentWeight: number = 0;
  currentQuantity: number = 0;
  isShowCalculation: boolean = true;
  isShowSave: boolean = false;
  showTaxValue: boolean = false;
  isShowAvailableAmount: boolean = false;
  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
    private router: Router, private toaster: ToastrService, private datePipe: DatePipe) {

  }

  deliveryForm = new UntypedFormGroup({
    stockDeliveryId: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    deliverOrderNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    deliveryDate: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    commodityName: new UntypedFormControl('string', [Validators.required, Validators.nullValidator]),
    quantity: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    weight: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    warehouseReceiptNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    depositorName: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    irmReceiptNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    date: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    vehicleNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    storageChargesInvoiceNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    atOfficeDate: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    amount: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    createdBy: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    currentStage: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    billPeriodType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    godownNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    stackNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    depositorId: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    depotId: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    deliveryRequestNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    memberLoginId: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    squareFeetCharges: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    spaceCharges: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    gst: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    totalCharges: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    depositApplicationNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    spaceType: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    handlingCharge: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    stackHolderHandlingDiscount: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    finalHandlingCharge: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    spaceChargeBasedOnCommodity: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    stackHolderSpaceDiscount: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    finalSpaceCharge: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    cgstAmount: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    cgst: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    sgst: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    sgstAmount: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    netAmount: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    roundedOff: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    invoiceNo: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
    totalBags: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    availableBalance: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    noOfMonth: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    commodityHandlingCharge: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    commodityRentCharge: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    packageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  deliveryFormErrors = {
    // deliverOrderNo: "",
    commodity: "",
    // warehouseReceiptNo: "",
    // storageChargesInvoiceNo: "",
    deliveryDate: "",
    quantity: "",
    weight: "",
    date: "",
    depositorName: "",
    irmReceiptNo: "",
    vehicleNo: "",
    atOfficeDate: "",
    totalCharges: "",
    billPeriodType: "",
    depositApplicationNumber: "",
    // godownNo: "",
    // stackNo: ""
  };

  ngOnInit() {
    // this.getApplicationNo();
    this.getTollgateDetailsForColdStorageDelivery();
    this.initial_data();
    this.getCommodityDetails();
  }

  ngOnDestroy() {
    sessionStorage.removeItem("storageType");
    sessionStorage.removeItem("serviceType");
    sessionStorage.removeItem("tollgateSeqNo");
  }

  // getApplicationNo() {
  //   this.apiServices.getColdStorageDeliveryAppNo()
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe(data => {
  //       this.deliveryForm.controls["deliverOrderNo"].setValue(data.depositAppNo);
  //     }, (err: any) => {
  //       this.toaster.error("Something went wrong, Please try again later");
  //     });
  // }

  getCommodityDetails() {
    this.apiServices.getCommodityDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.commodityDetails = data.filter(item => item.unit == 'Cold Storage' && item.specialConcessionRent !== 0.0);
        console.log(this.commodityDetails)
        const set = new Set(this.commodityDetails.map(item => item.commodityName));
        this.commodityNames = Array.from(set).sort((a, b) => a.localeCompare(b));
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  selectCommodity() {
    const commodityDetails = this.commodityDetails.find(item => item.commodityName === this.deliveryForm.get('commodityName')?.value);
    console.log(commodityDetails)
    if (commodityDetails.inclusiveGst == 'Yes') {
      this.showTaxValue = true;
    } else {
      this.showTaxValue = false;
    }
    this.showCalculation();
  }

  showCalculation() {
    this.isShowCalculation = true;
    this.isShowSave = false;
  }

  initial_data() {
    const now = new Date();
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
    this.deliveryForm.controls["deliveryDate"].setValue(formattedDateTime);
  }

  formValidation(deliveryForm: any) {
    // this.deliveryFormErrors.deliverOrderNo = "";
    this.deliveryFormErrors.commodity = "";
    // this.deliveryFormErrors.warehouseReceiptNo = "";
    this.deliveryFormErrors.billPeriodType = "";
    this.deliveryFormErrors.date = "";
    this.deliveryFormErrors.totalCharges = "";
    // this.deliveryFormErrors.godownNo = "";
    // this.deliveryFormErrors.stackNo = "";
    this.deliveryFormErrors.quantity = "";
    this.deliveryFormErrors.weight = "";
    this.deliveryFormErrors.depositApplicationNumber = "";
    this.deliveryFormErrors.depositorName = "";
    this.deliveryFormErrors.irmReceiptNo = "";
    this.deliveryFormErrors.vehicleNo = "";
    let hasError = false;

    // if (this.deliveryForm.get("deliverOrderNo").invalid) {
    //   this.deliveryFormErrors.deliverOrderNo = "*Delivery order no is required";
    //   hasError = true;
    // }
    if (this.deliveryForm.get("commodityName").invalid) {
      this.deliveryFormErrors.commodity = "*Commodity name is required";
      hasError = true;
    }
    // if (this.deliveryForm.get("warehouseReceiptNo").invalid) {
    //   this.deliveryFormErrors.warehouseReceiptNo = "*Warehouse receipt no is required";
    //   hasError = true;
    // }
    if (this.deliveryForm.get("date").invalid) {
      this.deliveryFormErrors.date = "*Date is required";
      hasError = true;
    }
    // if (this.deliveryForm.get("godownNo").invalid) {
    //   this.deliveryFormErrors.godownNo = "*Godown no is required";
    //   hasError = true;
    // }
    // if (this.deliveryForm.get("stackNo").invalid) {
    //   this.deliveryFormErrors.stackNo = "*Stack no is required";
    //   hasError = true;
    // }
    const quantity = this.deliveryForm.get("quantity").value;
    const totalBags = this.deliveryForm.get("totalBags").value;

    if (this.deliveryForm.get("quantity").invalid || quantity == 0) {
      this.deliveryFormErrors.quantity = "*Quantity number is required";
      hasError = true;
    } else if (quantity > totalBags) {
      this.deliveryFormErrors.quantity = `Total quantity (${quantity}) exceed the limit of ${totalBags}.`;
      hasError = true;
    }

    if (this.deliveryForm.get("weight").invalid || this.deliveryForm.get("weight").value == 0) {
      this.deliveryFormErrors.weight = "*Weight is required";
      hasError = true;
    }
    if (this.deliveryForm.get("depositApplicationNumber").invalid) {
      this.deliveryFormErrors.depositApplicationNumber = "*Deposit application number is required";
      hasError = true;
    }
    if (this.deliveryForm.get("depositorName").invalid) {
      this.deliveryFormErrors.depositorName = "*Depositor name is required";
      hasError = true;
    }
    if (this.isShowSave) {
      if (this.deliveryForm.get("totalCharges").invalid || this.deliveryForm.get("totalCharges").value == 0) {
        this.deliveryFormErrors.totalCharges = "*Total charges is required";
        hasError = true;
      }
    }
    if (this.deliveryForm.get("irmReceiptNo").invalid) {
      this.deliveryFormErrors.irmReceiptNo = "*Irm receipt no is required";
      hasError = true;
    }
    if (this.deliveryForm.get("vehicleNo").invalid) {
      this.deliveryFormErrors.vehicleNo = "*Vehicle Number is Required";
      hasError = true;
    }

    if (!hasError) {
      if (this.isShowSave) {
        if (this.deliveryForm.get('totalCharges').value <= this.currentAvailableBalance || this.currentAvailableBalance == 0) {
          this.addStockDelivery();
        } else {
          this.toaster.error("Insufficient available balance");
        }
      } else if (this.isShowCalculation) {
        this.saveColdStorageHandlingCharge();
      }
    }

  }



  // Get tollgate details and auto fetch the values
  getTollgateDetailsForColdStorageDelivery() {
    this.apiServices.getTollGateById(Number(sessionStorage.getItem('tollgateSeqNo')))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getPassDetails = data;
        this.deliveryForm.controls["vehicleNo"].setValue(this.getPassDetails.tollgateVehicleDetails.vehNumberPlate);
        this.deliveryForm.controls["irmReceiptNo"].setValue(this.getPassDetails.irmNo);
        this.deliveryForm.controls["amount"].setValue(this.getPassDetails.charges);
        this.deliveryForm.controls["storageType"].setValue(sessionStorage.getItem("storageType"));
        this.deliveryForm.controls["serviceType"].setValue(sessionStorage.getItem("serviceType"));
        this.depotId = this.getPassDetails.depotId;
        this.getAllColdStorageDepotIdBasedGetAll();
        this.getColdStorageAvailableBalance();
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  getAllColdStorageDepotIdBasedGetAll() {
    this.spinner.show();
    this.apiServices.getColdStorageDepotDetails(this.depotId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.depositMaintenance = data.sort((a, b) => b.stockMaintainId - a.stockMaintainId);
        this.listOfDepositApplicationNumber = [...new Set(this.depositMaintenance.filter(item => item.maintenanceType === 'DEPOSIT').map(item => item.depositApplicationNumber))];
        console.log(this.listOfDepositApplicationNumber)
      }, (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.error.message || "Something went wrong, Please try again later");
      })
  }

  getColdStorageAvailableBalance() {
    this.apiServices.getColdStorageAvailabilityBalances(this.depotId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.balanceDetails = data;
        this.deliveryForm.controls['availableBalance'].setValue(this.balanceDetails?.availableBalance);
        this.currentAvailableBalance = this.balanceDetails?.availableBalance;
        this.isShowAvailableAmount = true;
      }, (error: any) => {
        if(error.error.status === 404){
          this.isShowAvailableAmount  = false;
        }else{
          this.toaster.error(error.error.message || "Something went wrong, Please try again later");
        }
      })
  }

  selectedFormNumber(event: any) {
    const formNumber = event.target.value;
    console.log(formNumber);
    if (formNumber != "") {
      const JsonData = {
        applicationNumber: formNumber
      }
      this.getColdStorageCurrentDepositDetails(JsonData)
    } else {
      this.isShowFormDetails = false;
      this.isShowCalculation = false;
      this.isShowSave = false;
    }
  }

  getColdStorageCurrentDepositDetails(formData: any) {
    this.apiServices.getColdStorageCurrentDepositDetails(formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.currentDepositDetails = data;
        console.log(this.currentDepositDetails);
        if (this.currentDepositDetails?.closingPackage != 0) {
          this.isShowFormDetails = true;
          this.deliveryForm.controls["weight"].setValue(this.currentDepositDetails?.closingWeight);
          this.deliveryForm.controls["depositorName"].setValue(this.currentDepositDetails?.depositorName);
          this.deliveryForm.controls["commodityName"].setValue(this.currentDepositDetails?.commodityName);
          this.deliveryForm.controls["depotId"].setValue(this.currentDepositDetails?.depotId);
          this.deliveryForm.controls["depositorId"].setValue(this.balanceDetails?.depositorDetailsId);
          this.deliveryForm.controls["totalBags"].setValue(this.currentDepositDetails?.closingPackage);
          this.deliveryForm.controls["packageType"].setValue(this.currentDepositDetails?.packageType);
          const epochValue = this.currentDepositDetails?.date;
          const date = new Date(epochValue);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const year = date.getFullYear();
          const formattedDate = `${year}-${month}-${day}`;
          console.log("formattedDate: ", formattedDate);
          this.deliveryForm.controls['date'].setValue(formattedDate);
          this.isShowCalculation = true;
          this.isShowSave = false;
          this.selectCommodity();
        } else {
          this.isShowFormDetails = false;
          this.toaster.error("Already delivered in all bags, please select another deposit number")
        }
      }, (error: any) => {
        this.toaster.error(error.error.message || "Something went wrong, please try again")
      });
  }


  checkBagsCount(event: any) {
    const quantity = event.target.value;
    const totalBags = this.deliveryForm.get("totalBags").value;
    if (quantity > totalBags) {
      this.deliveryFormErrors.quantity = `Total quantity (${quantity}) exceed the limit of ${totalBags}.`;
    } else {
      this.deliveryFormErrors.quantity = "";
      const weightPerBag = this.currentDepositDetails?.closingWeight / totalBags;
      const calculatedWeight = weightPerBag * quantity;
      this.deliveryForm.controls["weight"].setValue((calculatedWeight).toFixed(3));
    }
  }


  checkQuantityValue(event: any) {
    const quantity = event.target.value;
    if (quantity != this.currentQuantity) {
      this.isShowCalculation = true;
      this.isShowSave = false;
    } else {
      this.isShowCalculation = false;
      this.isShowSave = true;
    }
  }

  checkWightValue(event: any) {
    const wight = event.target.value;
    if (wight != this.currentWeight) {
      this.isShowCalculation = true;
      this.isShowSave = false;
    } else {
      this.isShowCalculation = false;
      this.isShowSave = true;
    }
  }

  saveColdStorageHandlingCharge() {
    const deliveryDate = this.deliveryForm.get("deliveryDate").value;
    const epochTimestamp = new Date(deliveryDate).getTime() / 1000;
    const JsonData = {
      weight: this.deliveryForm.get('weight').value,
      endDate: (epochTimestamp * 1000),
      depositApplicationNumber: this.deliveryForm.get('depositApplicationNumber').value
    }
    this.currentWeight = JsonData.weight;
    this.currentQuantity = this.deliveryForm.get('quantity').value;
    this.apiServices.saveColdStorageDeliveryCharge(JsonData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.deliveryForm.controls['cgst'].setValue(data.cgst);
        this.deliveryForm.controls['sgst'].setValue(data.sgst);
        this.deliveryForm.controls['cgstAmount'].setValue(data.cgstAmount);
        this.deliveryForm.controls['sgstAmount'].setValue(data.sgstAmount);
        this.deliveryForm.controls['roundedOff'].setValue(data.roundedOff);
        this.deliveryForm.controls['handlingCharge'].setValue(data.handlingCharge);
        this.deliveryForm.controls['totalCharges'].setValue(data.totalCharges);
        this.deliveryForm.controls['stackHolderHandlingDiscount'].setValue(data.stackHolderHandlingDiscount);
        this.deliveryForm.controls['finalHandlingCharge'].setValue(data.finalHandlingCharge);
        this.deliveryForm.controls['spaceChargeBasedOnCommodity'].setValue(data.spaceChargeBasedOnCommodity);
        this.deliveryForm.controls['stackHolderSpaceDiscount'].setValue(data.stackHolderSpaceDiscount);
        this.deliveryForm.controls['finalSpaceCharge'].setValue(data.finalSpaceCharge);
        this.deliveryForm.controls['netAmount'].setValue(data.netAmount);
        this.deliveryForm.controls['noOfMonth'].setValue(data.noOfMonth);
        this.deliveryForm.controls['commodityHandlingCharge'].setValue(data.commodityHandlingCharge);
        this.deliveryForm.controls['commodityRentCharge'].setValue(data.commodityRentCharge);
        this.isShowCalculation = false;
        this.isShowSave = true;
      }, (error: any) => {
        this.spinner.hide();
        this.toaster.error(error.error.message || "Something went wrong, Please try again later");
      })
  }


  // Save the stock delivery details through API call
  addStockDelivery() {
    this.spinner.show();
    const deliveryDate = this.deliveryForm.get("deliveryDate").value;
    const epochTimestamp = new Date(deliveryDate).getTime() / 1000;
    this.deliveryForm.controls["deliveryDate"].setValue(epochTimestamp * 1000);
    const date = this.deliveryForm.get("date").value;
    const epochStartTimestamp = new Date(date).getTime() / 1000;
    this.deliveryForm.controls["date"].setValue(epochStartTimestamp * 1000);
    const atOfficeDate = this.deliveryForm.get("atOfficeDate").value;
    const epochEndTimestamp = new Date(atOfficeDate).getTime() / 1000;
    this.deliveryForm.controls["atOfficeDate"].setValue(Math.floor(new Date().getTime() / 1000));
    this.deliveryForm.controls["createdAt"].setValue(Math.floor(new Date().getTime() / 1000));
    this.deliveryForm.controls["storageType"].setValue(sessionStorage.getItem("storageType"));
    this.deliveryForm.controls["serviceType"].setValue("DELIVERY");
    this.apiServices.saveAdminColdStorageDeliveryRequest(this.deliveryForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.saveAlert();
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });
  }



  saveAlert() {
    Swal.fire({
      title: "Delivery Request Saved Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(["/main/frontOffice"]);
      }
    });
  }

}
